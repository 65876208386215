import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Tomasz Kriger" />
      <Layout>
        <SimpleBanner title="Tomasz Kriger">
          <StaticImage
            className="banner__image"
            src="../../images/band/tomasz-thumb.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Charyzma, uśmiech, dobrze ułożony włos i… gitara! Nikt nie jest w
            stanie oprzeć się wdziękowi Tomasza, który swoimi wykonami potrafi
            ekspresowo zapełnić cały parkiet, a jego gra na gitarze to gratka
            dla każdego melomana ! Śpiewa w głosach i doskonale odnajduje się w
            starszych klasykach. Jeden z głównych założycieli zespołu
            religijnego „Bliżej Nieba”, który był wielokrotnym laureatem
            Ogólnopolskiego Festiwalu Kolęd i Pastorałek, Festiwalu Piosenki
            Religijnej Kanaan oraz zdobywcą I miejsca na Festiwalu Muzyki
            Chrześcijańskiej Gloria Dei. Tomasz w naszym zespole, oprócz gry na
            gitarze akustycznej i elektrycznej, dostarcza mnóstwo energii i
            uśmiechu, jest również znakomitym wodzirejem, który wyciągnie do
            zabawy każdego…
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
